import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { RepliesFormat } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import DeleteActionModal from './DeleteActionModal/DeleteActionModal';
import localStyles from './MessageActionDelete.module.pcss';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import Button from '@aurora/shared-client/components/common/Button/Button';

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Variant of the button.
   */
  variant?: ButtonVariant;
  /**
   * RepliesFormat of the discussion style.
   * Can be either threaded or linear.
   */
  repliesFormat?: RepliesFormat;
}

/**
 * Action to delete a message
 *
 * @constructor
 *
 * @author Patricio Poratto, Adam Ayres
 */
const MessageActionDelete: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal,
  variant = ButtonVariant.PRIMARY,
  repliesFormat = RepliesFormat.Threaded
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_DELETE
  );
  const { confirmEmailStatus } = useRegistrationStatus();
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const isPreview = useIsPreviewMode();

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(module, {
    id: message.id,
    useCanDelete: true
  });

  if (textLoading || policiesLoading) {
    return null;
  }

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowCancelConfirm(false);
  }

  function handleOnDeleteClick(): void {
    if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else {
      setShowCancelConfirm(true);
    }
  }

  function renderDeleteAction(): React.ReactElement {
    return checkPolicy(policiesData?.message?.messagePolicies?.canDelete) &&
      !(isDraft(message) && isPreview) ? (
      <Dropdown.Item
        className={cx('lia-action-delete')}
        onClick={(): void => handleOnDeleteClick()}
        data-testid="MessageActionDelete"
      >
        {formatMessage('title')}
      </Dropdown.Item>
    ) : null;
  }

  function renderConfirmationDialog(): React.ReactElement {
    return (
      showCancelConfirm && (
        <DeleteActionModal
          show={showCancelConfirm}
          onHide={(): void => onHideOrCancelDialog()}
          message={message}
          repliesFormat={repliesFormat}
          hideSubjectModal={() => {
            if (isModalAction) {
              setTimeout(() => onHideSubjectModal(false), 500);
              onCloseSubjectModal();
            }
          }}
        />
      )
    );
  }

  return (
    <>
      {isModalAction ? (
        <Button
          data-testid="MessageActionDelete"
          size="lg"
          variant={variant}
          onClick={(): void => {
            onHideSubjectModal(true);
            setShowCancelConfirm(true);
          }}
        >
          {formatMessage('title')}
        </Button>
      ) : (
        renderDeleteAction()
      )}
      {renderConfirmationDialog()}
    </>
  );
};

export default MessageActionDelete;
