import React from 'react';
import type { MessageActionType } from '../types';
import CopyMessageLink from '../CopyMessageLink/CopyMessageLink';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import type { TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { VisibilityScope } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Action to copy a message's published url.
 *
 * @author Jitiendran KS
 */
const MessageActionCopyPublishedUrl: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const isPreview = useIsPreviewMode();
  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;

  return isDraft(message) && isPreview && !isArchived ? (
    <CopyMessageLink
      message={message}
      componentId={EndUserComponent.MESSAGE_ACTION_COPY_PUBLISHED_URL}
    />
  ) : null;
};

export default MessageActionCopyPublishedUrl;
