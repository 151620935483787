import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ActionButtonVariant,
  ButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import type { IconColor } from '@aurora/shared-client/components/common/Icon/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import useCommunityPolicies from '@aurora/shared-client/components/community/useCommunityPolicies';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import {
  canAccessPageBuilder as checkCanAccessPageBuilder,
  canEscalateForumMessage,
  canEscalateOwnForumMessage,
  canManageAbuseContent,
  canModerateNode
} from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import type {
  Board,
  Idea,
  Message,
  SalesforceMessage,
  TopicMessage,
  User
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  RepliesFormat,
  ContentWorkflowState,
  ConversationStyle,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { MessageActionMenuItem, SubscriptionActionVariant } from '../../../types/enums';
import type {
  BanUserPolicyQuery,
  BanUserPolicyQueryVariables,
  MessageActionMenuFragment,
  MessageContentWorkflowFragment
} from '../../../types/graphql-types';
import banUserPolicyQuery from '../../bans/BanUserPolicy.query.graphql';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import useEndUserPageDescriptor from '../../useEndUserPageDescriptor';
import { usePageEditorLink } from '../../usePageEditorLink';
import useTranslation from '../../useTranslation';
import MessageActionArchive from '../MessageActionArchive/MessageActionArchive';
import MessageActionBanMember from '../MessageActionBanMember/MessageActionBanMember';
import MessageActionBlockEdits from '../MessageActionBlockEdits/MessageActionBlockEdits';
import MessageActionConfirmAbuse from '../MessageActionConfirmAbuse/MessageActionConfirmAbuse';
import MessageActionCopyLink from '../MessageActionCopyLink/MessageActionCopyLink';
import MessageActionCopyPublishedUrl from '../MessageActionCopyPublishedUrl/MessageActionCopyPublishedUrl';
import MessageActionDelete from '../MessageActionDelete/MessageActionDelete';
import MessageActionDeleteDraft from '../MessageActionDeleteDraft/MessageActionDeleteDraft';
import MessageActionEdit from '../MessageActionEdit/MessageActionEdit';
import MessageActionEditBanMember from '../MessageActionEditBanMember/MessageActionEditBanMember';
import MessageActionEscalateToSalesforce from '../MessageActionEscalateToSalesforce/MessageActionEscalateToSalesforce';
import MessageActionHideSpam from '../MessageActionHideSpam/MessageActionHideSpam';
import MessageActionIgnoreAbuse from '../MessageActionIgnoreAbuse/MessageActionIgnoreAbuse';
import MessageActionInviteUsers from '../MessageActionInviteUsers/MessageActionInviteUsers';
import MessageActionMarkAsApproved from '../MessageActionMarkAsApproved/MessageActionMarkAsApproved';
import MessageActionMarkAsNotAbuse from '../MessageActionMarkAsNotAbuse/MessageActionMarkAsNotAbuse';
import MessageActionMarkAsNotSpam from '../MessageActionMarkAsNotSpam/MessageActionMarkAsNotSpam';
import MessageActionMarkAsSpam from '../MessageActionMarkAsSpam/MessageActionMarkAsSpam';
import MessageActionMove from '../MessageActionMove/MessageActionMove';
import MessageActionMoveComment from '../MessageActionMoveComment/MessageActionMoveComment';
import MessageActionReject from '../MessageActionReject/MessageActionReject';
import MessageActionReportAbuse from '../MessageActionReportAbuse/MessageActionReportAbuse';
import MessageActionSendMessage from '../MessageActionSendMessage/MessageActionSendMessage';
import MessageActionToggleView from '../MessageActionToggleView/MessageActionToggleView';
import MessageActionTurnOffComments from '../MessageActionTurnOffComments/MessageActionTurnOffComments';
import MessageActionUnarchive from '../MessageActionUnarchive/MessageActionUnarchive';
import MessageActionUpdateStatus from '../MessageActionUpdateStatus/MessageActionUpdateStatus';
import MessageSubscriptionAction from '../MessageSubscriptionAction/MessageSubscriptionAction';
import MessageVersionHistory from '../MessageVersionHistory/MessageVersionHistory';
import MessageActionEmailUser from '../MessageActionEmailUser/MessageActionEmailUser';
import type { MessageActionMenuItemAndProps } from '../MessageView/types';
import type { MessageActionType } from '../types';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import MessageActionUpdateRelatedContentUrlForArchivedMessage from '../MessageActionUpdateRelatedContentUrlForArchivedMessage/MessageActionUpdateRelatedContentUrlForArchivedMessage';

const log = getLog(module);

export interface Props {
  /**
   * The message to display the menu for.
   */
  message: MessageActionMenuFragment;
  /**
   * The list of actions to display in menu.
   */
  items: MessageActionMenuItemAndProps[];
  /**
   * The list of admin actions to display in menu
   */
  adminItems?: MessageActionMenuItemAndProps[];
  /**
   * Classname(s) to apply to the dropdown menu element.
   */
  className?: string;
  /**
   * Icon to use for the menu toggle.
   */
  icon?: MessageActionMenuIcon;
  /**
   * The icon color
   */
  iconColor?: IconColor;

  /** The icon size */
  iconSize?: IconSize;

  /**
   * Whether the component is rendered from the Manage Content Dashboard.
   */
  isDashboardAction?: boolean;

  /**
   * RepliesFormat of the discussion style.
   * Can be either threaded or linear.
   */
  repliesFormat?: RepliesFormat;
}

export enum MessageActionMenuIcon {
  GEAR = 'gear',
  ELLIPSIS = 'ellipsis'
}

interface MessageActionDescriptor {
  component: React.FC<React.PropsWithChildren<MessageActionType>>;
  policy(message: Message): boolean;
}

function renderMessageActionEdit({ message }): React.ReactElement {
  return <MessageActionEdit message={message} />;
}

function renderMessageActionEditInline({ message }): React.ReactElement {
  return <MessageActionEdit message={message} inline />;
}

function renderMessageActionSubscribe({ message }): React.ReactElement {
  return (
    <MessageSubscriptionAction
      message={message}
      variant={SubscriptionActionVariant.DROPDOWN_ITEM}
      useDivider={false}
    />
  );
}

/**
 * Action menu for a message.
 *
 * @constructor
 *
 * @author Patricio Poratto, Adam Ayres
 */
const MessageActionMenu: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  items,
  adminItems,
  icon = MessageActionMenuIcon.ELLIPSIS,
  className,
  iconColor,
  isDashboardAction = false,
  repliesFormat = RepliesFormat.Threaded
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MENU
  );
  const { isAnonymous, isPartiallyRegistered } = useRegistrationStatus();
  const { isContentWorkflowSupported } = ConversationStyleBehaviorHelper.getInstance(message.board);
  const { isEditable: isPageEditable } = useEndUserPageDescriptor();
  const { link: pageEditorLink } = usePageEditorLink();
  const tenant = useContext(TenantContext);
  const { authUser } = useContext(AppContext);
  const isBlog = message.board.conversationStyle === ConversationStyle.Blog;
  const isTkb = message.board.conversationStyle === ConversationStyle.Tkb;
  const isForum = message.board.conversationStyle === ConversationStyle.Forum;
  const {
    publicConfig: { salesforceIntegrationEnabled }
  } = tenant;

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanMarkMessageReadOnly: true,
      useCanFreezeTkbArticle: true,
      useCanMoveMessage: true,
      useCanModerateMessage: true,
      useCanReportAbuse: true,
      useCanDeleteDraft: true,
      useCanEdit: true,
      useCanDelete: true,
      useCanModerateEntity: true,
      useCanDoAuthoringActionsOnBlog: isBlog,
      useCanDoAuthoringActionsOnTkb: isTkb
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const { data: nodePoliciesData, loading: nodePoliciesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanViewMessageHistory: true,
      useCanManageAbuseContent: true,
      useCanSetIdeaStatus: true,
      useCanManageIdea: true,
      useCanEscalateMessage: isForum,
      useCanEscalateOwnMessage: isForum,
      useCanArchiveMessage: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id),
    false
  );

  const { data: communityPoliciesData, loading: communityPoliciesLoading } = useCommunityPolicies(
    module,
    {
      useCanModerateNode: true,
      useCanAccessPageBuilder: true
    },
    isAnonymous || !message,
    false
  );

  const { data: authUserData, loading: authUserLoading } = useQueryWithTracing<
    BanUserPolicyQuery,
    BanUserPolicyQueryVariables
  >(module, banUserPolicyQuery, {
    skip: isAnonymous || !message
  });

  const isPreview = useIsPreviewMode();

  if (
    textLoading ||
    policiesLoading ||
    nodePoliciesLoading ||
    authUserLoading ||
    communityPoliciesLoading
  ) {
    return null;
  }

  function renderMarkAsSpamAction(): React.ReactElement {
    return <MessageActionMarkAsSpam message={message} isDashboardAction={isDashboardAction} />;
  }

  function renderMarkAsNotSpamAction(): React.ReactElement {
    return <MessageActionMarkAsNotSpam message={message} isDashboardAction={isDashboardAction} />;
  }

  function renderMessageActionReject(): React.ReactElement {
    return (
      <MessageActionReject
        message={message}
        isDashboardAction={isDashboardAction}
        actionButtonVariant={ActionButtonVariant.DROP_DOWN_BUTTON}
      />
    );
  }

  /**
   *  Renders the action menu as a dropdown item.
   */
  function renderMessageActionMarkAsApproved(): React.ReactElement {
    return (
      <MessageActionMarkAsApproved
        message={message}
        actionButtonVariant={ActionButtonVariant.DROP_DOWN_BUTTON}
      />
    );
  }

  /**
   * Renders the ban member menu as a dropdown here
   */
  function renderMessageActionBanMember() {
    return <MessageActionBanMember user={message.author as User} />;
  }

  /**
   * Renders the email user action dropdown item
   * @returns React.ReactElement
   */
  function renderMessageActionEmailUser(): React.ReactElement {
    return <MessageActionEmailUser emailRecipients={message.author as User} />;
  }

  /**
   * Renders the delete action component.
   */
  function renderMessageActionDelete(): React.ReactElement {
    return <MessageActionDelete message={message} repliesFormat={repliesFormat} />;
  }

  const canAccessPageBuilder: boolean = checkCanAccessPageBuilder(communityPoliciesData?.coreNode);

  const itemToActionMap: Record<MessageActionMenuItem, MessageActionDescriptor> = {
    [MessageActionMenuItem.EDIT]: {
      component: renderMessageActionEdit,
      policy: (): boolean => {
        const messageUserContext = (message as MessageContentWorkflowFragment)?.contentWorkflow
          ?.userContext;
        if (
          messageUserContext?.canRecall &&
          (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
            ContentWorkflowState.ScheduledForPublish
        ) {
          return true;
        } else if (message.editFrozen) {
          return (
            messageUserContext?.canEdit &&
            checkPolicy(policiesData?.message.messagePolicies?.canFreezeTkbArticle)
          );
        } else if (isContentWorkflowSupported) {
          return messageUserContext?.canEdit;
        } else {
          return checkPolicy(policiesData?.message?.messagePolicies?.canEdit);
        }
      }
    },
    [MessageActionMenuItem.EDIT_INLINE]: {
      component: renderMessageActionEditInline,
      policy: (): boolean => checkPolicy(policiesData?.message?.messagePolicies?.canEdit)
    },
    [MessageActionMenuItem.COPY_PUBLISHED_URL]: {
      component: MessageActionCopyPublishedUrl,
      policy: (): boolean => isDraft(message) && isPreview
    },
    [MessageActionMenuItem.DELETE]: {
      component: renderMessageActionDelete,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canDelete) &&
        !(isDraft(message) && isPreview)
    },
    [MessageActionMenuItem.VIEW_TOGGLE]: {
      component: MessageActionToggleView,
      policy: (): boolean => message.repliesCount > 0
    },
    [MessageActionMenuItem.COPY_LINK]: {
      component: MessageActionCopyLink,
      policy: (): boolean => message.depth !== 0
    },
    [MessageActionMenuItem.SUBSCRIBE]: {
      component: renderMessageActionSubscribe,
      policy: (): boolean => !isAnonymous
    },
    [MessageActionMenuItem.TURN_OFF_COMMENTS]: {
      component: MessageActionTurnOffComments,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canMarkMessageReadOnly)
    },
    [MessageActionMenuItem.BLOCK_EDITS]: {
      component: MessageActionBlockEdits,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canFreezeTkbArticle) && isTkb
    },
    [MessageActionMenuItem.MOVE]: {
      component: MessageActionMove,
      policy: (): boolean => checkPolicy(policiesData?.message?.messagePolicies?.canMoveMessage)
    },
    [MessageActionMenuItem.MOVE_COMMENT]: {
      component: MessageActionMoveComment,
      policy: (): boolean => checkPolicy(policiesData?.message?.messagePolicies?.canMoveMessage)
    },
    [MessageActionMenuItem.MARK_AS_NOT_SPAM]: {
      component: renderMarkAsNotSpamAction,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canModerateSpamMessage)
    },
    [MessageActionMenuItem.MARK_AS_SPAM]: {
      component: renderMarkAsSpamAction,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canModerateSpamMessage)
    },
    [MessageActionMenuItem.REPORT_ABUSE]: {
      component: MessageActionReportAbuse,
      policy: (): boolean => {
        return (
          checkPolicy(policiesData?.message?.messagePolicies?.canReportAbuse) ||
          isAnonymous ||
          isPartiallyRegistered
        );
      }
    },
    [MessageActionMenuItem.ARTICLE_HISTORY]: {
      component: MessageVersionHistory,
      policy: (): boolean =>
        checkPolicy((nodePoliciesData?.coreNode as Board)?.boardPolicies.canViewMessageHistory)
    },
    [MessageActionMenuItem.HIDE_SPAM]: {
      component: MessageActionHideSpam,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canModerateSpamMessage)
    },
    [MessageActionMenuItem.DELETE_DRAFT]: {
      component: MessageActionDeleteDraft,
      policy: (): boolean => {
        return (
          isDraft(message) &&
          isPreview &&
          checkPolicy(policiesData?.message?.messagePolicies?.canDeleteDraft)
        );
      }
    },
    [MessageActionMenuItem.CONFIRM_ABUSE]: {
      component: MessageActionConfirmAbuse,
      policy: (): boolean => true
    },
    [MessageActionMenuItem.EMAIL_USER]: {
      component: renderMessageActionEmailUser,
      policy: (): boolean => true
    },
    [MessageActionMenuItem.IGNORE_ABUSE]: {
      component: MessageActionIgnoreAbuse,
      policy: (): boolean => true
    },
    [MessageActionMenuItem.REJECT]: {
      component: renderMessageActionReject,
      policy: (): boolean =>
        checkPolicy(policiesData?.message?.messagePolicies?.canModerateSpamMessage) ||
        canManageAbuseContent(nodePoliciesData?.coreNode) ||
        checkPolicy(policiesData?.message?.messagePolicies?.canModerateEntity)
    },
    [MessageActionMenuItem.MARK_AS_NOT_ABUSE]: {
      component: MessageActionMarkAsNotAbuse,
      policy: (): boolean => canManageAbuseContent(nodePoliciesData?.coreNode)
    },
    [MessageActionMenuItem.MARK_AS_APPROVED]: {
      component: renderMessageActionMarkAsApproved,
      policy: (): boolean => canModerateNode(communityPoliciesData?.coreNode)
    },
    [MessageActionMenuItem.SEND_MESSAGE]: {
      component: MessageActionSendMessage,
      policy: (): boolean => true
    },
    [MessageActionMenuItem.BAN_MEMBER]: {
      component: renderMessageActionBanMember,
      policy: (): boolean => checkPolicy(authUserData?.self?.userPolicies?.canBanUsers)
    },
    [MessageActionMenuItem.EDIT_BAN_MEMBER]: {
      component: MessageActionEditBanMember,
      policy: (): boolean => checkPolicy(authUserData?.self?.userPolicies?.canBanUsers)
    },
    [MessageActionMenuItem.UPDATE_STATUS]: {
      component: MessageActionUpdateStatus,
      policy: (): boolean =>
        message.board.conversationStyle === ConversationStyle.Idea &&
        checkPolicy((nodePoliciesData?.coreNode as Idea)?.ideaPolicies?.canSetIdeaStatus)
    },
    [MessageActionMenuItem.INVITE]: {
      component: MessageActionInviteUsers,
      policy: (): boolean =>
        message.board.conversationStyle === ConversationStyle.Occasion &&
        checkPolicy(policiesData?.message.messagePolicies.canEdit)
    },
    [MessageActionMenuItem.ESCALATE_TO_SALESFORCE]: {
      component: MessageActionEscalateToSalesforce,
      policy: (): boolean =>
        salesforceIntegrationEnabled &&
        isForum &&
        !(message as SalesforceMessage).isEscalated &&
        (canEscalateForumMessage(nodePoliciesData?.coreNode) ||
          (authUser.id === message.author.id &&
            canEscalateOwnForumMessage(nodePoliciesData?.coreNode)))
    },
    [MessageActionMenuItem.ARCHIVE]: {
      component: MessageActionArchive,
      policy: (): boolean =>
        message.depth === 0 &&
        checkPolicy((nodePoliciesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage)
    },
    [MessageActionMenuItem.UNARCHIVE]: {
      component: MessageActionUnarchive,
      policy: (): boolean =>
        message.depth === 0 &&
        (message as Message)?.visibilityScope === VisibilityScope.Archived &&
        checkPolicy((nodePoliciesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage)
    },
    [MessageActionMenuItem.RELATED_CONTENT_URL]: {
      component: MessageActionUpdateRelatedContentUrlForArchivedMessage,
      policy: (): boolean =>
        message.depth === 0 &&
        checkPolicy((nodePoliciesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage)
    }
  };

  function renderMenuItem(item: MessageActionMenuItemAndProps): React.ReactElement {
    const Component = itemToActionMap[item.item]?.component;
    if (Component) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component key={item.item} message={message} {...item.props} />;
    }
    log.error('No message action menu for action item type %s', item);
    return null;
  }

  const hasItemsToDisplay =
    items?.filter(item => itemToActionMap[item.item]?.policy(message as Message)).length > 0;

  const hasAdminItemsToDisplay =
    adminItems?.filter(adminItem => itemToActionMap[adminItem.item]?.policy(message as Message))
      .length > 0;

  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;

  function iconTypeDisplay(): React.ReactElement {
    const iconSize = icon === MessageActionMenuIcon.ELLIPSIS ? IconSize.PX_16 : IconSize.PX_20;
    const renderIcon =
      icon === MessageActionMenuIcon.ELLIPSIS ? Icons.EllipsisIcon : Icons.GearIcon;

    return <Icon size={iconSize} icon={renderIcon} color={iconColor} />;
  }

  return (
    (hasItemsToDisplay || (isPageEditable && canAccessPageBuilder) || hasAdminItemsToDisplay) && (
      <Dropdown
        drop="down"
        className={cx(className)}
        data-testid="MessageActionMenu"
        title={formatMessage('actionMenu.title')}
        aria-label={formatMessage('actionMenu.ariaLabel')}
      >
        <Dropdown.Toggle
          as={Button}
          aria-label={formatMessage('toggleButtonLabel')}
          className={cx('lia-g-icon-btn')}
          variant={ButtonVariant.NO_VARIANT}
          data-testid="MessageActionMenu.toggle"
        >
          {iconTypeDisplay()}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={cx('lia-g-popper-right')}
          popperConfig={dropdownPopperConfig}
          align="right"
          renderOnMount
          data-testid="MessageActionMenu.item"
        >
          {hasAdminItemsToDisplay && adminItems.map(adminItem => renderMenuItem(adminItem))}
          {(hasAdminItemsToDisplay ||
            (isPageEditable && message.depth === 0 && canAccessPageBuilder)) &&
            hasItemsToDisplay && <Dropdown.Divider />}
          {items.map(item => renderMenuItem(item))}
          {isPageEditable && message.depth === 0 && canAccessPageBuilder && !isArchived && (
            <>
              <Dropdown.Divider />
              {pageEditorLink(
                <Dropdown.Item data-testid="MessageActionMenu.EditPage">
                  {formatMessage('editPage')}
                </Dropdown.Item>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )
  );
};

export default MessageActionMenu;
