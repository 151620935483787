import type { UseFormSetError } from 'react-hook-form';
import type { ErrorOption } from 'react-hook-form/dist/types/errors';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import type { Error } from '@aurora/shared-generated/types/graphql-schema-types';

interface FieldError<FormData> {
  /**
   * Name of the field in the form
   */
  fieldName: FieldPath<FormData>;
  /**
   * error details for a given error
   */
  error: ErrorOption;

  /**
   * Whether the field should get focus or not
   */
  shouldFocus: boolean;
}

export interface FieldToErrorMap<FormData> {
  /**
   * Name of the field in the form
   */
  fieldName: FieldPath<FormData>;

  /**
   * Corresponding error field name in the API response
   */
  errorFieldName?: string;

  /**
   * Allows custom error message.
   */
  customErrorMessage?: (error: Error) => string;
}

/**
 * Attemps to return a custom error defined by the FieldToErrorMap.
 * Falls back to returning the error message sent by the server.
 * This should be modified to return a generic error message using i18n/useTranslation, as the server returns messages that are not
 * translatable or customizable. However, this will require additional work and should be done as part of a different ticket,
 * perhaps LIA-73633 , as this will need to be converted to a hook & components that currently rely on the server messages updated. TODO
 * @param error The error from the server
 * @param mappedField The individual field map
 * @returns An error message
 */
function getErrorMessage<FormData>(error: Error, mappedField?: FieldToErrorMap<FormData>): string {
  if (mappedField?.customErrorMessage) {
    return mappedField.customErrorMessage(error);
  }

  return error.message;
}

/**
 * Set the focus on the first field with error when fieldToErrorMap is provided by the caller
 * The order of fields provided in fieldToErrorMap determines the order of setting focus.
 * @returns A new array of errors, potentially changed
 */
function attemptFocus<FormData>(
  errorList: FieldError<FormData>[],
  fieldToErrorMap: FieldToErrorMap<FormData>[]
): FieldError<FormData>[] {
  const result = [...errorList];

  if (fieldToErrorMap) {
    fieldToErrorMap.every(field => {
      const fieldErrorIndex = result.findIndex(error => {
        return error.fieldName === field.fieldName;
      });
      if (fieldErrorIndex !== -1) {
        result[fieldErrorIndex].shouldFocus = true;
        return false;
      }
      return true;
    });
  }

  return result;
}

/**
 * Ensure an error field has focus (may happen when the caller has not provided an exhaustive orderField).
 * If not, set focus to the first field with error.
 * @returns A new array of errors, potentially changed
 */
function ensureFocus<FormData>(errorList: FieldError<FormData>[]): FieldError<FormData>[] {
  const result = [...errorList];

  if (result?.length > 0) {
    const focussedError = result.find(error => error.shouldFocus);
    if (!focussedError) {
      result[0].shouldFocus = true;
    }
  }

  return result;
}

function getErrorList<FormData>(
  errors: Error[],
  fieldToErrorMap: FieldToErrorMap<FormData>[]
): FieldError<FormData>[] {
  const fieldErrorMessages: Partial<Record<FieldPath<FormData>, string>> = {};

  errors.forEach(error => {
    const { fields } = error;

    if (!fields) {
      return;
    }

    fields.forEach(field => {
      if (fieldToErrorMap) {
        const mappedField = fieldToErrorMap.find(item => item.errorFieldName === field);
        if (mappedField && !fieldErrorMessages[mappedField.fieldName as string]) {
          fieldErrorMessages[mappedField.fieldName as string] = getErrorMessage(error, mappedField);
          return;
        }
      }
      if (!fieldErrorMessages[field]) {
        fieldErrorMessages[field] = getErrorMessage(error);
      }
    });
  });

  const errorList: FieldError<FormData>[] = Object.entries(fieldErrorMessages).map(
    ([key, value]: [FieldPath<FormData>, string]) => {
      return {
        fieldName: key,
        error: {
          type: 'manual',
          message: value
        },
        shouldFocus: false
      };
    }
  );

  const attemptedFocusErrorList = attemptFocus(errorList, fieldToErrorMap);
  const ensuredFocusErrorList = ensureFocus(attemptedFocusErrorList);

  return ensuredFocusErrorList;
}

/**
 * Render errors
 */
function renderErrors<FormData>(
  errorList: FieldError<FormData>[],
  setError: UseFormSetError<FormData>
) {
  errorList.forEach(error => {
    setError(error.fieldName as FieldPath<FormData>, error.error, {
      shouldFocus: error.shouldFocus
    });
  });
}

/**
 * Handles the errors returned by the mutation call to submit a form when the mutation fails
 *
 * @param errors List of errors during mutation call for submitting the form
 * @param setError Function to set error on a form field
 * @param fieldToErrorMap List of fields in the required order along with the mapped errorFieldPath.
 * Used to map field to errors when the API returns field names which are not same as set in the form.
 * This is also used to set focus on the first field with error when the mutaion does not return errors in the appropriate order
 */
function handleFormSubmitMutationErrorStates<FormData>(
  errors: Error[],
  setError: UseFormSetError<FormData>,
  fieldToErrorMap?: FieldToErrorMap<FormData>[]
): void {
  const errorList = getErrorList(errors, fieldToErrorMap);
  renderErrors(errorList, setError);
}

export default handleFormSubmitMutationErrorStates;
