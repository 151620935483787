import React, { useState } from 'react';
import type { MessageActionType } from '../types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import useTranslation from '../../useTranslation';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import {
  ModerationStatus,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { Board, TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { Dropdown } from 'react-bootstrap';
import AddRelatedContentUrlModal from './AddRelatedContentUrlModal/AddRelatedContentUrlModal';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import Button from '@aurora/shared-client/components/common/Button/Button';

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Variant of the button.
   */
  variant?: ButtonVariant;
}

/**
 * Action to update related content url for an archived message
 * @constructor
 *
 * @author Akash Goenka
 */
const MessageActionUpdateRelatedUrlForArchivedMessage: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal,
  variant = ButtonVariant.PRIMARY
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_UPDATE_RELATED_CONTENT_URL_FOR_ARCHIVED_MESSAGE
  );
  const [showRelatedContentUrlModal, setShowRelatedContentUrlModal] = useState<boolean>(false);

  const { isAnonymous } = useRegistrationStatus();
  const isRejected = message.moderationData.status === ModerationStatus.Rejected;

  const { data: policiesData, loading: policiesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanArchiveMessage: true
    },
    isAnonymous || !message
  );

  if (textLoading || policiesLoading) {
    return null;
  }

  const showRelatedContentUrlAction =
    (message as TopicMessage).visibilityScope === VisibilityScope.Archived &&
    message.depth === 0 &&
    !isRejected &&
    checkPolicy((policiesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage);

  const shouldUpdateRelatedContentUrl = !!(message as TopicMessage).archivalData?.suggestedUrl;

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowRelatedContentUrlModal(false);
  }

  /**
   * Renders the modal to update the related content url for archived message
   */
  function renderRelatedContentUrlModal() {
    return (
      <AddRelatedContentUrlModal
        show={showRelatedContentUrlModal}
        onHide={() => onHideOrCancelDialog()}
        message={message}
        hideSubjectModal={() => {
          if (isModalAction) {
            setTimeout(() => onHideSubjectModal(false), 500);
            onCloseSubjectModal();
          }
        }}
      />
    );
  }

  return (
    <>
      {isModalAction ? (
        <Button
          data-testid="UpdateRelatedContentUrl"
          size="lg"
          variant={variant}
          onClick={() => {
            onHideSubjectModal(true);
            setShowRelatedContentUrlModal(true);
          }}
        >
          {formatMessage('title', { isEditingUrl: shouldUpdateRelatedContentUrl })}
        </Button>
      ) : (
        showRelatedContentUrlAction && (
          <Dropdown.Item
            onClick={() => setShowRelatedContentUrlModal(true)}
            data-testid="UpdateRelatedContentUrl"
          >
            {formatMessage('title', { isEditingUrl: shouldUpdateRelatedContentUrl })}
          </Dropdown.Item>
        )
      )}
      {showRelatedContentUrlModal && renderRelatedContentUrlModal()}
    </>
  );
};

export default MessageActionUpdateRelatedUrlForArchivedMessage;
