import type { ApolloCache } from '@apollo/client';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  BlockEditsMutation,
  BlockEditsMutationVariables,
  MessageActionMenuFragment
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import blockEditsMutation from '../MessageActionBlockEdits/BlockEdits.mutation.graphql';
import type { MessageActionType } from '../types';

export enum BlockEditAction {
  BLOCK = 'blockEdits',
  UNBLOCK = 'unblockEdits'
}

/**
 * Action to block message edits
 * @param message
 * @constructor
 *
 * @author Shraddha Padmanabhan
 */
const MessageActionBlockEdits: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_BLOCK_EDITS
  );
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const [blockEdits] = useMutationWithTracing<BlockEditsMutation, BlockEditsMutationVariables>(
    module,
    blockEditsMutation
  );
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanFreezeTkbArticle: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param key
   * @param titleKey toast title.
   * @param messageKey toast message.
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    key: string,
    titleKey: string,
    messageKey: string
  ): void {
    const id = uidSeed(`block-edits-${titleKey}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title: formatMessage(`${key}.${titleKey}`),
      autohide: true,
      message: formatMessage(`${key}.${messageKey}`),
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Updates cache
   * @param cache
   * @param isEditsFrozen
   * @param messageInformation
   */
  function updateCache(
    cache: ApolloCache<{}>,
    isEditsFrozen: Boolean,
    messageInformation: MessageActionMenuFragment
  ): () => void {
    const id = cache.identify(message);
    const connection = messageInformation.editFrozen;
    async function doUpdate(contextMessage): Promise<void> {
      cache.modify({
        id,
        fields: {
          editFrozen() {
            return contextMessage?.editFrozen;
          }
        }
      });
    }
    const data = {
      id,
      editFrozen: isEditsFrozen
    };

    doUpdate(data);
    // return a rollback function
    return (): void => {
      doUpdate(connection);
    };
  }

  /**
   * Action handler for blocking edits
   */
  async function handleAction(): Promise<void> {
    let rollback;
    const key = message.editFrozen ? BlockEditAction.UNBLOCK : BlockEditAction.BLOCK;
    const mutationPayload: BlockEditsMutationVariables = {
      id: message.id,
      isEditsFrozen: !message.editFrozen
    };

    const { data: response, errors } = await blockEdits({
      variables: mutationPayload,
      update: (cache, { errors: updateErrors }): void => {
        if (!updateErrors?.length) {
          rollback = updateCache(cache, !message.editFrozen, message);
        }
      }
    });
    if (errors?.length > 0 || response.setMessageEditsFrozen.errors) {
      if (rollback) {
        rollback();
      }
      renderToast(ToastAlertVariant.DANGER, key, 'failureTitle', 'failureMessage');
      return;
    }
    renderToast(ToastAlertVariant.SUCCESS, key, 'successTitle', 'successMessage');
  }
  const canFreezeArticle = checkPolicy(policiesData?.message.messagePolicies?.canFreezeTkbArticle);
  return canFreezeArticle &&
    message.board.conversationStyle === ConversationStyle.Tkb &&
    !isArchived ? (
    <Dropdown.Item onClick={() => handleAction()} data-testid="MessageActionBlockEdits">
      {message.editFrozen
        ? formatMessage(`menuItem.${BlockEditAction.UNBLOCK}`)
        : formatMessage(`menuItem.${BlockEditAction.BLOCK}`)}
    </Dropdown.Item>
  ) : null;
};
export default MessageActionBlockEdits;
