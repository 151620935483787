import useCommunityContentArchivalProperties from '@aurora/shared-client/components/community/useCommunityContentArchivalProperties';
import React from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type { ErrorOption, FieldPath } from 'react-hook-form';
import { useUIDSeed } from 'react-uid';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import type {
  MessageActionMenuFragment,
  AddRelatedContentUrlMutation,
  AddRelatedContentUrlMutationVariables
} from '../../../../types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import useTranslation from '../../../useTranslation';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import ModalFormBuilder from '@aurora/shared-client/helpers/modal/ModalFormBuilder/ModalFormBuilder';
import formSchema from './AddRelatedContentUrlModal.form.json';
import { FormInputFieldInputType } from '@aurora/shared-client/components/form/enums';
import { URL_PATTERN } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import addRelatedContentUrlMutation from './AddRelatedContentUrl.mutation.graphql';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import handleErrorStates, {
  type FieldToErrorMap
} from '@aurora/shared-client/helpers/form/MutationError/MutationErrorHelper';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import type { TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import contextMessageQuery from '../../../context/ContextMessage.query.graphql';

interface Props {
  /**
   * Whether the modal is currently being displayed
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden
   *
   * @callback
   */
  onHide: () => void;
  /**
   * The archived message
   */
  message: MessageActionMenuFragment;
  /**
   * Callback function for hiding the subject overview modal
   */
  hideSubjectModal?: () => void;
}

interface FormData {
  /**
   * The related content url
   */
  relatedContentUrl: string;
}

/**
 * Modal to add related content for an archived message
 *
 * @author Akash Goenka
 */
const AddRelatedContentUrlModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  message,
  hideSubjectModal
}) => {
  const i18n = useTranslation(EndUserComponent.ADD_RELATED_CONTENT_URL_MODAL);
  const { formatMessage, loading: textLoading } = i18n;
  const cx = useClassNameMapper();
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const relatedContentUrl = (message as TopicMessage).archivalData?.suggestedUrl;

  const [addRelatedContentUrl] = useMutationWithTracing<
    AddRelatedContentUrlMutation,
    AddRelatedContentUrlMutationVariables
  >(module, addRelatedContentUrlMutation);

  const { data, loading } = useCommunityContentArchivalProperties(module);

  if (textLoading || loading) {
    return null;
  }

  const isExternalUrlAllowed =
    data?.community?.contentArchiveProperties?.isContentArchiveExternalUrlsEnabled;

  const formSpec = new ModalFormBuilder<FormData>('AddRelatedContentUrlForm', i18n, {
    schema: formSchema,
    cx
  })
    .addInputField({
      name: 'relatedContentUrl',
      inputType: FormInputFieldInputType.TEXT,
      focus: true,
      defaultValue: relatedContentUrl || '',
      attributes: {
        placeholder: isExternalUrlAllowed
          ? formatMessage(
              'AddRelatedContentUrlForm.relatedContentUrl.allowExternalUrls.placeholder'
            )
          : formatMessage('AddRelatedContentUrlForm.relatedContentUrl.placeholder')
      },
      validations: {
        pattern: {
          value: URL_PATTERN,
          message: formatMessage('AddRelatedContentUrlForm.relatedContentUrl.pattern.error')
        }
      }
    })
    .addSubmitAction({
      title: formatMessage('submitActionTitle', { isEditAction: !!relatedContentUrl })
    })
    .addCancelAction()
    .build();

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param key
   * @param title toast title.
   * @param toastMessage toast message.
   */
  function renderToast(alertVariant: ToastAlertVariant, title: string, toastMessage: string): void {
    const id = uidSeed(`add-related-content-url-${title}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title,
      autohide: true,
      message: toastMessage,
      delay: 4000
    };
    addToast(toastProps);
  }

  async function onSubmit(
    formData: FormData,
    action: string,
    event: React.FormEvent<HTMLFormElement>,
    setError: (name: FieldPath<FormData>, error: ErrorOption) => void
  ): Promise<void> {
    if (action === 'cancel') {
      onHide();
      return;
    } else {
      const mutationPayload: AddRelatedContentUrlMutationVariables = {
        messageId: message.id,
        relatedContentUrl: formData?.relatedContentUrl
      };

      const { data: response, errors } = await addRelatedContentUrl({
        variables: mutationPayload,
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: contextMessageQuery,
            variables: {
              id: message.id
            }
          }
        ]
      });
      if (errors?.length > 0) {
        renderToast(
          ToastAlertVariant.DANGER,
          formatMessage('failureTitle'),
          formatMessage('failureMessage')
        );
      } else if (response.updateRelatedContentUrlForArchivedMessage.errors) {
        const fieldMapper: FieldToErrorMap<FormData>[] = [
          { fieldName: 'relatedContentUrl', errorFieldName: 'relatedUrl' }
        ];
        handleErrorStates(
          response?.updateRelatedContentUrlForArchivedMessage?.errors,
          setError,
          fieldMapper
        );
      } else {
        onHide();
        hideSubjectModal();
        renderToast(
          ToastAlertVariant.SUCCESS,
          formatMessage('successTitle', { isEditAction: !!relatedContentUrl }),
          formatMessage('successMessage', { isEditAction: !!relatedContentUrl })
        );
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      data-testid="AddRelatedContentUrlModal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {formatMessage('modalTitle', { isEditingUrl: !!relatedContentUrl })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputEditForm formSpec={formSpec} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default AddRelatedContentUrlModal;
