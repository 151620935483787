import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { isNodeIdea } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import type { Idea, TopicMessage } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type {
  IdeaStatusInformationQuery,
  IdeaStatusInformationQueryVariables
} from '../../../types/graphql-types';
import ideaStatusPropertiesQuery from '../../ideas/IdeaStatusInformation.query.graphql';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';

const UpdateStatusModal = dynamic(() => import('./UpdateStatusModal/UpdateStatusModal'));

/**
 * The message action to update the status of an idea message
 * @param message - the message
 * @constructor
 */
const MessageActionUpdateStatus: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { contextNode } = useContext(AppContext);
  const { isAnonymous } = useRegistrationStatus();
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState<boolean>(false);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_UPDATE_STATUS
  );

  const {
    data: propertiesData,
    loading: propertiesLoading,
    error: propertiesQueryError
  } = useQueryWithTracing<IdeaStatusInformationQuery, IdeaStatusInformationQueryVariables>(
    module,
    ideaStatusPropertiesQuery,
    {
      variables: {
        id: message.board.id,
        useIdeaStatusProperties: true
      }
    }
  );

  const {
    data: nodePoliciesData,
    loading: nodePoliciesLoading,
    error: nodePoliciesError
  } = useNodePolicies(module, {
    id: message.board.id,
    useCanSetIdeaStatus: true,
    useCanManageIdea: true
  });

  if (
    !isNodeIdea(contextNode) ||
    isAnonymous ||
    textLoading ||
    nodePoliciesLoading ||
    nodePoliciesError ||
    propertiesLoading ||
    propertiesQueryError
  ) {
    return null;
  }

  const { isIdeaStatusEnabled } = (propertiesData.coreNode as Idea).ideaStatusProperties;
  const { defaultStatusValue } = (propertiesData.coreNode as Idea).ideaStatusProperties;
  const canSetIdeaStatus: boolean = checkPolicy(
    (nodePoliciesData.coreNode as Idea).ideaPolicies.canSetIdeaStatus
  );
  const isArchived = (message as TopicMessage).visibilityScope === VisibilityScope.Archived;

  /**
   * Renders update status modal
   */
  const renderUpdateStatusModal = (): React.ReactElement => {
    return (
      <UpdateStatusModal
        show={showUpdateStatusModal}
        onHide={() => setShowUpdateStatusModal(false)}
        message={message}
        defaultStatus={defaultStatusValue}
      />
    );
  };

  return (
    !isArchived && (
      <>
        {message.board.conversationStyle === ConversationStyle.Idea &&
          isIdeaStatusEnabled &&
          canSetIdeaStatus && (
            <>
              <Dropdown.Item
                onClick={(): void => setShowUpdateStatusModal(true)}
                data-testid="UpdateStatusOption"
              >
                {formatMessage('title')}
              </Dropdown.Item>
              {showUpdateStatusModal && renderUpdateStatusModal()}
            </>
          )}
      </>
    )
  );
};

export default MessageActionUpdateStatus;
