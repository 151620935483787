import useCommunityContentArchivalProperties from '@aurora/shared-client/components/community/useCommunityContentArchivalProperties';
import React from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import type {
  MessageActionMenuFragment,
  ArchiveMessageMutation,
  ArchiveMessageMutationVariables
} from '../../../../types/graphql-types';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import useTranslation from '../../../useTranslation';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import ModalFormBuilder from '@aurora/shared-client/helpers/modal/ModalFormBuilder/ModalFormBuilder';
import formSchema from './MessageArchivalModal.form.json';
import { FormInputFieldInputType } from '@aurora/shared-client/components/form/enums';
import { URL_PATTERN } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import archiveMessageMutation from './ArchiveMessage.mutation.graphql';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import { useApolloClient } from '@apollo/client';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { useUIDSeed } from 'react-uid';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import handleErrorStates, {
  type FieldToErrorMap
} from '@aurora/shared-client/helpers/form/MutationError/MutationErrorHelper';
import type { FieldPath, ErrorOption } from 'react-hook-form';

interface Props {
  /**
   * Whether the modal is currently being displayed
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden
   *
   * @callback
   */
  onHide: () => void;
  /**
   * The message to be archived
   */
  message: MessageActionMenuFragment;
}

interface FormData {
  /**
   * The related content url for the message to be archived
   */
  relatedUrl: string;
}

/**
 * Modal to archive a message
 *
 * @author Manasvini Arul
 */
const MessageArchivalModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  message
}) => {
  const i18n = useTranslation(EndUserComponent.MESSAGE_ARCHIVAL_MODAL);
  const { formatMessage, loading: textLoading, FormattedMessage } = i18n;
  const cx = useClassNameMapper();
  const { addToast } = useToasts();
  const uidSeed = useUIDSeed();
  const { router } = useEndUserRoutes();
  const client = useApolloClient();

  const [archiveMessage] = useMutationWithTracing<
    ArchiveMessageMutation,
    ArchiveMessageMutationVariables
  >(module, archiveMessageMutation);

  const { data, loading } = useCommunityContentArchivalProperties(module);

  if (textLoading || loading) {
    return null;
  }

  const isExternalUrlAllowed =
    data?.community?.contentArchiveProperties?.isContentArchiveExternalUrlsEnabled;

  const formSpec = new ModalFormBuilder<FormData>('MessageArchivalForm', i18n, {
    schema: formSchema,
    cx
  })
    .addInputField({
      name: 'relatedUrl',
      inputType: FormInputFieldInputType.TEXT,
      defaultValue: '',
      focus: true,
      attributes: {
        placeholder: isExternalUrlAllowed
          ? formatMessage('MessageArchivalForm.relatedUrl.allowExternalUrls.placeholder')
          : formatMessage('MessageArchivalForm.relatedUrl.placeholder')
      },
      validations: {
        pattern: {
          value: URL_PATTERN,
          message: formatMessage('MessageArchivalForm.relatedUrl.pattern.error')
        }
      }
    })
    .addSubmitAction()
    .addCancelAction()
    .build();

  /**
   * Renders toast banner or flyout
   *
   * @param alertVariant toast alert variant.
   * @param titleKey toast title.
   * @param messageKey toast message.
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    titleKey: string,
    messageKey: string | React.FC
  ): void {
    const id = uidSeed(`archive-message-${titleKey}`);
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id,
      toastVariant,
      alertVariant,
      title: titleKey,
      autohide: true,
      message: messageKey,
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Onsubmit function to archive a message
   * @param formData
   * @param action
   * @param event
   * @param setError
   */
  async function onSubmit(
    formData: FormData,
    action: string,
    event: React.FormEvent<HTMLFormElement>,
    setError: (name: FieldPath<FormData>, error: ErrorOption) => void
  ): Promise<void> {
    if (action === 'cancel') {
      onHide();
      return;
    } else {
      const mutationPayload: ArchiveMessageMutationVariables = {
        messageId: message.id,
        relatedUrl: formData?.relatedUrl
      };

      const { data: response, errors } = await archiveMessage({
        variables: mutationPayload
      });
      if (errors?.length > 0) {
        renderToast(
          ToastAlertVariant.DANGER,
          formatMessage('failureTitle'),
          formatMessage('failureMessage')
        );
        return;
      }
      if (response?.archiveMessage?.errors?.length > 0) {
        const fieldMapper: FieldToErrorMap<FormData>[] = [
          { fieldName: 'relatedUrl', errorFieldName: 'relatedUrl' }
        ];
        handleErrorStates(response?.archiveMessage?.errors, setError, fieldMapper);
        return;
      }
      onHide();
      renderToast(ToastAlertVariant.SUCCESS, formatMessage('successTitle'), () => (
        <FormattedMessage
          id="successMessage"
          values={{
            bold: (...chunks) => (<strong>{chunks}</strong>) as React.ReactNode,
            subject: message.subject
          }}
        />
      ));
      client.cache.evict({ id: client.cache.identify(message), broadcast: false });
      client.cache.gc();
      await router.addQueryParam(EndUserQueryParams.ARCHIVED_CONTENT, 'true');
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      centered
      data-testid="MessageArchivalModal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{formatMessage('modalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputEditForm formSpec={formSpec} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default MessageArchivalModal;
