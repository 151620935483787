import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import {
  ModerationStatus,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type { MessageActionType } from '../types';
import MessageArchivalModal from './MessageArchivalModal/MessageArchivalModal';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useTranslation from '../../useTranslation';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';

/**
 * Action to archive a message
 *
 * @constructor
 *
 * @author Manasvini Arul
 */
const MessageActionArchive: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const [showArchivalModal, setShowArchivalModal] = useState<boolean>(false);
  const { isAnonymous } = useRegistrationStatus();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_ARCHIVE
  );
  const { contextMessage } = useContext(AppContext);
  const isRejected = contextMessage.moderationData.status === ModerationStatus.Rejected;

  const { data: policiesData, loading: policiesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanArchiveMessage: true
    },
    isAnonymous || !message
  );

  if (textLoading || policiesLoading) {
    return null;
  }

  const showArchiveAction =
    contextMessage.visibilityScope !== VisibilityScope.Archived &&
    message.depth === 0 &&
    !isRejected &&
    checkPolicy((policiesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage);

  const renderArchivalModal = (): React.ReactElement => {
    return (
      <MessageArchivalModal
        show={showArchivalModal}
        onHide={() => setShowArchivalModal(false)}
        message={message}
      />
    );
  };

  return (
    <>
      {showArchiveAction && (
        <Dropdown.Item
          onClick={() => setShowArchivalModal(true)}
          data-testid="ArchiveMessageOption"
        >
          {formatMessage('title')}
        </Dropdown.Item>
      )}
      {showArchivalModal && renderArchivalModal()}
    </>
  );
};

export default MessageActionArchive;
