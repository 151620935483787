import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { Board, Message } from '@aurora/shared-generated/types/graphql-schema-types';
import { VisibilityScope } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import Button from '@aurora/shared-client/components/common/Button/Button';

const MessageUnarchivalModal = dynamic(
  () => import('./MessageUnarchivalModal/MessageUnarchivalModal')
);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Variant of the button.
   */
  variant?: ButtonVariant;
}

/**
 * The action to unarchive a message
 * @author BE.Abhijith
 */
const MessageActionUnarchive: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal,
  variant = ButtonVariant.PRIMARY
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_UNARCHIVE
  );
  const [showUnarchiveMessageModal, setShowUnarchiveMessageModal] = useState<boolean>(false);
  const { isAnonymous } = useRegistrationStatus();
  const { data: policiesData, loading: policiesLoading } = useNodePolicies(
    module,
    {
      id: message.board.id,
      useCanArchiveMessage: true
    },
    isAnonymous || !message
  );

  if (textLoading || policiesLoading) {
    return null;
  }

  const showUnarchiveDropdownItem =
    message.depth === 0 &&
    checkPolicy((policiesData?.coreNode as Board)?.boardPolicies?.canArchiveMessage) &&
    (message as Message).visibilityScope === VisibilityScope.Archived;

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (isModalAction) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowUnarchiveMessageModal(false);
  }

  /**
   * Render the unarchive message modal
   * @returns {React.ReactElement}
   */
  const renderUnarchiveMessageModal = (): React.ReactElement => {
    return (
      <MessageUnarchivalModal
        show={showUnarchiveMessageModal}
        onHide={() => {
          onHideOrCancelDialog();
        }}
        message={message}
        hideSubjectModal={() => {
          if (isModalAction) {
            setTimeout(() => onHideSubjectModal(false), 500);
            onCloseSubjectModal();
          }
        }}
      />
    );
  };

  return (
    <>
      {isModalAction ? (
        <Button
          data-testid="MessageActionUnarchiveButton"
          size="lg"
          variant={variant}
          onClick={() => {
            onHideSubjectModal(true);
            setShowUnarchiveMessageModal(true);
          }}
        >
          {formatMessage('title')}
        </Button>
      ) : (
        showUnarchiveDropdownItem && (
          <Dropdown.Item
            onClick={() => setShowUnarchiveMessageModal(true)}
            data-testid="MessageActionUnarchiveDropdownItem"
          >
            {formatMessage('title')}
          </Dropdown.Item>
        )
      )}
      {showUnarchiveMessageModal && renderUnarchiveMessageModal()}
    </>
  );
};

export default MessageActionUnarchive;
