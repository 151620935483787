import type { QueryHookOptions } from '@apollo/client/react/types/types';
import type {
  CommunityArchivalPropertiesQuery,
  CommunityArchivalPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import contentArchivalPropertiesQuery from './CommunityArchivalProperties.query.graphql';
import type { ExtendedQueryResult } from '../useQueryWithTracing';
import useQueryWithTracing from '../useQueryWithTracing';

/**
 * Gets content archival properties for the current community.
 *
 * @param module The module
 * @param options Optional options to pass to the query
 * @author Manasvini Arul
 */
export default function useCommunityContentArchivalProperties(
  module: NodeModule | string,
  options?: QueryHookOptions<
    CommunityArchivalPropertiesQuery,
    CommunityArchivalPropertiesQueryVariables
  >
): ExtendedQueryResult<
  CommunityArchivalPropertiesQuery,
  CommunityArchivalPropertiesQueryVariables
> {
  return useQueryWithTracing<
    CommunityArchivalPropertiesQuery,
    CommunityArchivalPropertiesQueryVariables
  >(module, contentArchivalPropertiesQuery, options);
}
